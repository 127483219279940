import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../layout"
import styled from "styled-components"
import { motion } from "framer-motion"
import Img from "gatsby-image"

const postLayout = props => {
  const { markdownRemark } = props.data
  return (
    <Layout>
      <PostWrapper
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 1,
        }}
      >
        <p className="breadcrumb">
          {" "}
          <Link
            style={{ textDecoration: `none`, color: `var(--teal-200)` }}
            to="/blog"
          >
            Blog
          </Link>{" "}
          <span>/</span>{" "}
          <Link
            style={{ textDecoration: `none`, color: `var(--teal-200)` }}
            to={`/blog#${markdownRemark.frontmatter.tag}`}
          >
            {markdownRemark.frontmatter.tag}
          </Link>{" "}
          {"  "}
          <span>/</span>
          {"  "}
          {markdownRemark.frontmatter.title}
        </p>
        <div className="blog-heading">
          <div
            className="img-box"
            style={{
              display: `grid`,
              width: `100%`,
              height: `300px`,
              overflow: `hidden`,
              alignContent: `center`,
            }}
          >
            <Img
              fluid={
                markdownRemark.frontmatter.featuredImage.childImageSharp.fluid
              }
              style={{ borderRadius: `8px` }}
            />
          </div>
          <h3 classname="lead">{markdownRemark.frontmatter.title}</h3>
          <div className="post-deets">
            <p>{markdownRemark.frontmatter.author}</p>
            <span style={{ marginLeft: `5px`, marginRight: `5px` }}>·</span>
            <p>{markdownRemark.frontmatter.date}</p>
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: markdownRemark.html,
          }}
        />
      </PostWrapper>
    </Layout>
  )
}

export default postLayout

// Styled components
const PostWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 0 200px 200px;
  padding: 50px 20px 20px;
  color: var(--grey-300);

  .blog-heading {
    display: grid;
    place-items: start start;

    .post-deets {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  a {
    color: var(--blue-200);
    text-decoration: none;
    transition: ease-in-out 0.3s;
    padding-left: 2px;
    padding-right: 2px;

    &:hover {
      color: var(--white);
      background: var(--blue);
    }
  }

  @media (max-width: 1000px) {
    margin: 0 20px 20px;

    .blog-left,
    .blog-right {
      width: 100%;
    }
  }
`

// Static query == used anywhere; page query == ONLY used with pages
// Static doesn't accept params and can't use ctx; page query does
export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author
        tag
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      html
    }
  }
`
